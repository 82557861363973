$(function(){
    $('input#day').on('keyup', function (){

        var val = parseInt($(this).val());

        if (this.value.length >= this.maxLength && val > 0 && val <= 31) {
            $('input#month').focus();
        }

    });

    $('input#month').on('keyup', function (){

        var val = parseInt($(this).val());

        if (this.value.length >= this.maxLength && val > 0 && val <= 12) {
            $('input#year').focus();
        }

    });
});